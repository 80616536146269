/* eslint-disable jsx-quotes */
import Head from 'next/head';

const BreadcrumbList = ({ posts, articles }) => {
  const site = 'https://hanagold.vn/';
  const listBreadcrumb = posts.map((post, i) => {
    return {
      '@type': 'ListItem',
      position: i + 1,
      item: {
        '@id': post['@id']
          ? post['@id']
          : `${site}${articles}/${post.code || post.seo_url}`,
        name: post.name || post.title,
      },
    };
  });

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
                    {
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": ${JSON.stringify(listBreadcrumb)}
                        
                    }
            `,
        }}
        type="application/ld+json"
      ></script>{' '}
    </Head>
  );
};
export default BreadcrumbList;
