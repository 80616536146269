import { 
    LOAD_LIST_STORE
  } from '../../constants/ActionTypes';
  
import { fetchApi } from "../../utils/api";
export const getAllStore = async (payload, dispatch) => {
    try {
        let response = await fetchApi(`/app/store/all`, "get", payload);
        if (response.code !== 200) {
            return [];
        }
        await dispatch(loadListStore(response.data.rows));
        return response.data.rows;
    } catch (error) {
        return [];
    }
};

export function loadListStore(payload) {
    return  {
        type: LOAD_LIST_STORE,
        payload
    };
}